import React from 'react';

import * as styles from './blue-slider-card.module.scss';

export const BlueSliderCard = ({ lineOne, lineTwo, lineThree, lineFour }) => {
  return (
    <div className={styles.card}>
      <span className={styles.textLine}>{lineOne}</span>
      {lineTwo && <span className={styles.textLine}>{lineTwo}</span>}
      {lineThree && <span className={styles.textLine}>{lineThree}</span>}
      {lineFour && <span className={styles.textLine}>{lineFour}</span>}
    </div>
  );
};
