import React from 'react';

import { NewStandardSection } from '@commons/new-standard-section/NewStandardSection';
import { BlueSliderCard } from '@commons/blue-slider-card/BlueSliderCard';

import { customSoftwareBenefitsSliderData, customSoftwareBenefitsSectionProps } from './payload';
import { sliderSettings } from './slider-settings';

import Slider from 'react-slick';

import * as styles from './custom-software-benefits-slider.module.scss';

export const CustomSoftwareBenefitsSlider = () => {
  return (
    <NewStandardSection
      classNames={{
        outerWrapper: styles.sectionOuterWrapper,
        innerWrapper: styles.innerWrapper,
        titleStyles: styles.sectionTitle,
      }}
      {...customSoftwareBenefitsSectionProps}
    >
      <Slider {...sliderSettings}>
        {customSoftwareBenefitsSliderData.map((service, index) => (
          <BlueSliderCard key={index} {...service} />
        ))}
      </Slider>
      <div className={styles.mobileDataContainer}>
        {customSoftwareBenefitsSliderData.map((service, index) => (
          <BlueSliderCard key={index} {...service} />
        ))}
      </div>
    </NewStandardSection>
  );
};
