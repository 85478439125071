import React from 'react';

import Layout from '@src/layouts';
import SEO from '@commons/SEO';

import { CustomSoftwareBenefitsSlider } from '@pages-impl/custom-software-development';

import {
  MobileHeaderLayoutA,
  BlocksChangingBackgroundColorOnHover,
  NewClientsTestimonials,
  BlocksInRowsWithSomeActive,
  TechStack,
  SectionUnderHeaderWithUnderline,
  DesktopHeaderLayoutB,
  ColoredBlocksWithRedirects,
} from '@src/components/commons';

import {
  customSoftwareDevelopmentHeaderProps,
  customSoftwareDevelopmentSeoProps,
  techStackProps,
  underHeaderSectionProps,
  blocksChangingBackgroundColorOnHoverProps,
  blocksInRowsWithSomeActiveProps,
  coloredBlocksWithRedirectsProps,
  companyInNumbersProps,
} from '@pages-impl/custom-software-development/payload';
import { CompanyInNumbers } from '@pages-impl/home';

export default function CustomSoftwareDevelopment(props) {
  return (
    <Layout
      displayTopBanner={false}
      contactFormType="services"
      contactFormTitle="Build your software with our custom development services"
      {...props}
    >
      <SEO {...customSoftwareDevelopmentSeoProps} />
      <MobileHeaderLayoutA {...customSoftwareDevelopmentHeaderProps} />
      <DesktopHeaderLayoutB {...customSoftwareDevelopmentHeaderProps} />
      <SectionUnderHeaderWithUnderline {...underHeaderSectionProps} />
      <BlocksInRowsWithSomeActive {...blocksInRowsWithSomeActiveProps} />
      <CustomSoftwareBenefitsSlider />
      <BlocksChangingBackgroundColorOnHover {...blocksChangingBackgroundColorOnHoverProps} />
      <NewClientsTestimonials />
      <TechStack {...techStackProps}>
        <span>
          We know that the right choice of technology is one of the most crucial decisions during{' '}
          <b>product development. </b>
          That&apos;s why we select only reliable technologies
        </span>
        <span>
          {' '}
          – we work with the best solutions available to ensure your code and product are of the{' '}
          <b>highest possible quality.</b>
        </span>
        <span>What can you expect from us?</span>
      </TechStack>
      <CompanyInNumbers {...companyInNumbersProps} />
      <ColoredBlocksWithRedirects {...coloredBlocksWithRedirectsProps} />
    </Layout>
  );
}
