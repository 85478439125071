// extracted by mini-css-extract-plugin
export var blocksInRowsInnerWrapper = "payload-module--blocks-in-rows-inner-wrapper--35a0d";
export var blocksInRowsTitle = "payload-module--blocks-in-rows-title--2bde9";
export var blocksInRowsTitlesContainer = "payload-module--blocks-in-rows-titles-container--a6b43";
export var blocksSectionInnerWrapper = "payload-module--blocks-section-inner-wrapper--babf9";
export var coloredBlocksInnerWrapper = "payload-module--colored-blocks-inner-wrapper--c4d44";
export var companyInNumbersSection = "payload-module--company-in-numbers-section--3d43a";
export var companyInNumbersTitlesContainer = "payload-module--company-in-numbers-titles-container--7e3db";
export var header = "payload-module--header--7d234";
export var headerBorder = "payload-module--header-border--5f2e1";
export var headerMobileBorder = "payload-module--header-mobile-border--dbdbf";
export var techStackContainer = "payload-module--tech-stack-container--d974b";
export var techStackDesktopContainer = "payload-module--tech-stack-desktop-container--b7124";
export var techStackInnerWrapper = "payload-module--tech-stack-inner-wrapper--be352";
export var underHeaderButton = "payload-module--under-header-button--1b288";
export var underHeaderContentWrapper = "payload-module--under-header-content-wrapper--018fd";
export var underHeaderInnerWrapper = "payload-module--under-header-inner-wrapper--bcd34";
export var underHeaderSliderInnerWrapper = "payload-module--under-header-slider-inner-wrapper--fda15";
export var underHeaderText = "payload-module--under-header-text--b2c61";