export const sliderSettings = {
  slidesToShow: 3,
  autoplay: false,
  infinite: true,
  className: 'custom-benefits-slider',
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 1230,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};
