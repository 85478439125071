import React from 'react';

import featuredImage from './images/featured-image.jpg';
import { go, cPlusPlus, python, rust, react, kubernetes, dpdk, p4 } from '@images/logos-techstack-bigger';

import { paths } from '@src/constants/pathsEnum';

import * as styles from './payload.module.scss';

export const customSoftwareDevelopmentSeoProps = {
  seoTitle: 'Custom Software Development Services – CodiLime',
  seoDescription:
    'Looking for a partner who can provide you with custom software development services? Our top-notch engineers have been doing it since 2011.',
  featuredImage: `https://codilime.com${featuredImage}`,
};

export const customSoftwareDevelopmentHeaderProps = {
  title: (
    <>
      Custom Software <br />
      Development <br />
      Services
    </>
  ),
  titleHasBorder: true,
  classNames: {
    header: styles.header,
    border: styles.headerBorder,
  },
  titleBorderWidth: styles.headerMobileBorder,
};

export const underHeaderSectionProps = {
  textPartOne:
    'At CodiLime, we know how to build your technologically advanced product to make it exceptional, win the market, and amaze end users. From UX & UI design, through network and cloud expertise to tailored',
  textPartTwo:
    ' software development. You can expect a custom approach to your project and requirements at every stage of the product life cycle.',
  contactButtonLabel: "Let's work together",
  classNames: {
    innerWrapper: styles.underHeaderInnerWrapper,
    contentWrapper: styles.underHeaderContentWrapper,
    text: styles.underHeaderText,
    button: styles.underHeaderButton,
    sliderInnerWrapper: styles.underHeaderSliderInnerWrapper,
  },
};

export const blocksInRowsWithSomeActiveProps = {
  sectionProps: {
    title: 'How can our developers help you?',
    id: 'services',
    classNames: {
      titleStyles: styles.blocksInRowsTitle,
      titlesContainer: styles.blocksInRowsTitlesContainer,
      innerWrapper: styles.blocksInRowsInnerWrapper,
    },
  },
  sections: [
    {
      title: 'MVP services',
      description:
        'We can help you with creating user stories, a clickable prototype and designing the product architecture.',
      buttons: [{ buttonHref: paths.SERVICES.MVP }],
    },
    {
      title: 'UX & UI design',
      description:
        'Our designers are experts in designing UX & UI for more complex and data-heavy applications (especially network and cloud solutions).',
      buttons: [{ buttonLabel: 'UX & UI services', buttonHref: paths.SERVICES.UX_UI_DESIGN }],
    },
    {
      title: 'Software development',
      description:
        'CodiLime’s team uses technologies for both front- and back-end daily, but we also can deep dive into low-level and work closely with physical hardware.',
      buttons: [
        { buttonLabel: 'Frontend', buttonHref: paths.SERVICES.FRONTEND },
        { buttonLabel: 'Backend', buttonHref: paths.SERVICES.BACKEND },
      ],
    },
    {
      title: 'Cloud-native',
      description:
        'Does your project require cloud expertise? No problem! We can build and integrate your solution whether it involves private, public, or hybrid cloud environments.',
    },
    {
      title: 'Network solutions',
      description:
        'We have experienced network engineers, SDN/NFV specialists, cloud enthusiasts, and automation experts on board who have experience with large-scale deployments and maintenance.',
      buttons: [{ buttonHref: paths.SERVICES.NETWORK_SOLUTION_SERVICES }],
    },
  ],
};

export const blocksChangingBackgroundColorOnHoverProps = {
  title: 'Custom software development - our approach',
  id: 'custom-software-development-our-approach',
  classNames: { innerWrapper: styles.blocksSectionInnerWrapper },
};

export const techStackProps = {
  sectionProps: {
    id: 'technology-stack',
    classNames: { innerWrapper: styles.techStackInnerWrapper },
  },
  titleOnLeft: 'Technology stack at CodiLime',
  threeColumnsOnMobile: true,
  defaultColor: '#00aeef',
  classNames: {
    desktopContainer: styles.techStackDesktopContainer,
    techStackContainer: styles.techStackContainer,
  },
  data: [
    go,
    cPlusPlus,
    python,
    rust,
    react,
    kubernetes,
    dpdk,
    p4,
    {
      link: paths.TECHNOLOGIES,
      textOnly: 'Check more',
    },
  ],
};

export const companyInNumbersProps = {
  id: 'about-codilime',
  title: 'Meet the CodiLime company',
  imgSrc: '',
  classNames: {
    section: styles.companyInNumbersSection,
    titlesContainer: styles.companyInNumbersTitlesContainer,
  },
};

export const coloredBlocksWithRedirectsProps = {
  title: 'Other services',
  subtitle:
    'Are you looking for other services that can help you achieve your goals? We offer a wide range of top-notch services that will enhance your business operations.',
  blocks: [
    {
      text: (
        <>
          <span>Network professional</span>
          <span>services</span>
        </>
      ),
      link: paths.SERVICES.NETWORK_PROFESSIONAL_SERVICES,
    },
    {
      text: (
        <>
          <span>Product Design</span>
        </>
      ),
      link: paths.SERVICES.PRODUCT_DESIGN,
    },
    {
      text: (
        <>
          <span>Data engineering</span>
          <span>services</span>
        </>
      ),
      link: paths.SERVICES.DATA_ENGINEERING,
    },
    {
      text: (
        <>
          <span>DevOps services</span>
        </>
      ),
      link: paths.SERVICES.DEVOPS,
    },
  ],
  classNames: {
    innerWrapper: styles.coloredBlocksInnerWrapper,
  },
};
