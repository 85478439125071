export const customSoftwareBenefitsSectionProps = {
  id: 'benefits',
  title: 'Custom software benefits',
};

export const customSoftwareBenefitsSliderData = [
  {
    lineOne: 'You know your product inside-out,',
    lineTwo: 'and it’s easy to implement',
    lineThree: 'additional features, as it’s built',
    lineFour: 'from scratch.',
  },
  {
    lineOne: 'You can focus on developing',
    lineTwo: 'the in-demand functionalities.',
  },
  {
    lineOne: 'You don’t need to adjust',
    lineTwo: 'to the solutions available',
    lineThree: 'on the market.',
  },
  {
    lineOne: 'You can get a tailored product',
    lineTwo: 'that fully meets your',
    lineThree: 'requirements.',
  },
  {
    lineOne: 'There won’t be any',
    lineTwo: 'functionalities you don’t use,',
    lineThree: 'but you still need to pay for them.',
  },
];
